// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-de-contact-js": () => import("./../../../src/pages/de/contact.js" /* webpackChunkName: "component---src-pages-de-contact-js" */),
  "component---src-pages-de-dienstleistungen-js": () => import("./../../../src/pages/de/dienstleistungen.js" /* webpackChunkName: "component---src-pages-de-dienstleistungen-js" */),
  "component---src-pages-de-index-js": () => import("./../../../src/pages/de/index.js" /* webpackChunkName: "component---src-pages-de-index-js" */),
  "component---src-pages-de-referenz-js": () => import("./../../../src/pages/de/Referenz.js" /* webpackChunkName: "component---src-pages-de-referenz-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-references-js": () => import("./../../../src/pages/en/references.js" /* webpackChunkName: "component---src-pages-en-references-js" */),
  "component---src-pages-en-services-js": () => import("./../../../src/pages/en/services.js" /* webpackChunkName: "component---src-pages-en-services-js" */),
  "component---src-pages-hr-contact-js": () => import("./../../../src/pages/hr/contact.js" /* webpackChunkName: "component---src-pages-hr-contact-js" */),
  "component---src-pages-hr-index-js": () => import("./../../../src/pages/hr/index.js" /* webpackChunkName: "component---src-pages-hr-index-js" */),
  "component---src-pages-hr-referenca-js": () => import("./../../../src/pages/hr/referenca.js" /* webpackChunkName: "component---src-pages-hr-referenca-js" */),
  "component---src-pages-hr-usluge-js": () => import("./../../../src/pages/hr/usluge.js" /* webpackChunkName: "component---src-pages-hr-usluge-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-pl-index-js": () => import("./../../../src/pages/pl/index.js" /* webpackChunkName: "component---src-pages-pl-index-js" */),
  "component---src-pages-pl-kontakt-js": () => import("./../../../src/pages/pl/kontakt.js" /* webpackChunkName: "component---src-pages-pl-kontakt-js" */),
  "component---src-pages-pl-rezerwacja-js": () => import("./../../../src/pages/pl/rezerwacja.js" /* webpackChunkName: "component---src-pages-pl-rezerwacja-js" */),
  "component---src-pages-pl-uslugi-js": () => import("./../../../src/pages/pl/usługi.js" /* webpackChunkName: "component---src-pages-pl-uslugi-js" */),
  "component---src-pages-reference-js": () => import("./../../../src/pages/reference.js" /* webpackChunkName: "component---src-pages-reference-js" */),
  "component---src-pages-sluzby-js": () => import("./../../../src/pages/sluzby.js" /* webpackChunkName: "component---src-pages-sluzby-js" */)
}

